import React, { useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import LMDataGrid from './LMDataGrid'
import { getBackOfficeExtensions, saveBackofficeExtensionConfig } from '../APIUtils/BackendHandler'
import PropTypes from 'prop-types'

export default function Extensions ({ agentID }) {
  const [rows, setRows] = React.useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      editable: false,
      type: 'string'
    },
    {
      field: 'Agent',
      headerName: 'User',
      editable: false,
      type: 'singleSelect',
      minWidth: 300
    },
    {
      field: 'Extension',
      headerName: 'Extension',
      editable: false,
      type: 'string',
      minWidth: 180
    },
    {
      field: 'RecordCall',
      headerName: 'Enable call recording',
      editable: false,
      type: 'boolean',
      minWidth: 200
    },
    {
      field: 'OfferVoicemail',
      headerName: 'Enable Voicemail',
      editable: false,
      type: 'boolean',
      minWidth: 150
    },
    {
      field: 'UseCustomMessage',
      headerName: 'Use Custom Message',
      editable: true,
      type: 'boolean',
      minWidth: 180
    },
    {
      field: 'VoicemailLanguage',
      headerName: 'Voicemail Language',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['', 'English', 'French'],
      minWidth: 180
    },
    {
      field: 'VoicemailMessage',
      headerName: 'Voicemail Message',
      editable: true,
      type: 'string',
      minWidth: 200,
      flex: 1
    }
  ]
  const validateRow = (item) => {
    console.log('validating row')
    console.log(item)
    // check if the agent field has a value
    if (item.Agent === '') {
      setErrorMessage('Please select a user from the list.')
      return false
    }

    if (item.UseCustomMessage === true && item.VoicemailMessage.trim().length === 0) {
      setErrorMessage('A voicemail message must be entered if Use Custom Message is true')
      return false
    }

    if (item.UseCustomMessage === true && item.VoicemailLanguage.trim().length === 0) {
      setErrorMessage('A voicemail language must be selected if Use Custom Message is true')
      return false
    }
    return true
  }

  const saveRow = async (updatedRow) => {
    // should return a promise that resolve to true or false to indicate sucess
    console.log('saving row')

    let success = validateRow(updatedRow)
    if (!success) {
      return false
    }

    success = await saveBackofficeExtensionConfig(updatedRow)
    if (!success) {
      setErrorMessage('Failed to save data, please check that all entries are valid and that you are connected to the internet')
    }

    return success
  }

  useEffect(() => {
    const doLoadEntries = async () => {
      const entries = await getBackOfficeExtensions(agentID)
      setRows(entries)
      setOpenBackdrop(false)
    }
    doLoadEntries()
  }, [])

  return (
        <div style={{ margin: '10px' }}>
            <CssBaseline/>
            <LMDataGrid rows={rows} setRows={setRows} basecolumns={columns} EditFieldNameToFocus='VoicemailMessage'
                        saveRow={saveRow} openBackdrop={openBackdrop}
                        setOpenBackdrop={setOpenBackdrop} errorMessage={errorMessage}
            />
        </div>
  )
}
Extensions.propTypes = {
  agentID: PropTypes.string
}
