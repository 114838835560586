import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'

export default function Login ({ onSignIn }) {
  const [licenseAccepted, setLicenseAccepted] = React.useState(false)
  const [openLicense, setOpenLicense] = React.useState(false)
  const [scroll, setScroll] = React.useState('paper')

  const handleLicenseAcceptedChange = (event) => {
    setLicenseAccepted(event.target.checked)
  }

  const handleClickLicenseOpen = (scrollType) => {
    setOpenLicense(true)
    setScroll(scrollType)
  }

  const handleCloseLicense = () => {
    setOpenLicense(false)
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [openLicense])

  return (
        <div id="page_content" style={{ padding: '5px', backgroundColor: window.getConfig.LoginBackGroundColor, height: '100vh' }}>
            <div style={{
              width: '600px',
              padding: '20px',
              margin: 'auto',
              backgroundColor: 'white',
              position: 'absolute',
              border: '2px solid #ffffff',
              borderRadius: '5px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}>
                <CssBaseline />
                <Box textAlign='center' padding='10px'>
                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/35333/Black.png" width="280px"></img><br/>
                    <Button variant="contained" sx={{ backgroundColor: window.getConfig.MainColor }} disabled={!licenseAccepted} onClick={() => { onSignIn() }}>Log me in</Button>
                </Box>
                <div>
                  <Button sx={{ color: window.getConfig.MainColor }} onClick={ () => { handleClickLicenseOpen('paper') } }>Show License</Button>
                  <FormGroup>
                    <FormControlLabel componentsProps={{ typography: { fontSize: '12px' } }} control={<Checkbox checked={licenseAccepted} onChange={handleLicenseAcceptedChange} size="small" />} label="By accessing the Portal and using the Software, Client confirms their understanding and acceptance of these terms and conditions." />
                  </FormGroup>
                </div>
            </div>
            <Dialog
              open={openLicense}
              onClose={handleCloseLicense}
              scroll={scroll}>
              <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
              <DialogContent dividers={scroll === 'paper'}>
                <div
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}>
                    <Box sx={{ backgroundColor: 'white', padding: '10px', boxShadow: 1 }}>
                        <ol style={{ fontSize: '14px' }}>
                          <li>Subscription Requirement:
                            <ol style={{ listStyleType: 'disc', paddingBottom: '0' }}>
                              <li style={{ marginLeft: '0em' }}>Access to this Portal and use of the Software is contingent upon
                              Client having a valid and paid subscription with Local Measure. Client’s access is subject to the
                                terms and conditions of the subscription agreement.</li>
                            </ol>
                          </li>
                          <li>Ownership and License:
                            <ol style={{ listStyleType: 'disc', paddingBottom: '0' }}>
                              <li style={{ marginLeft: '0em' }}>The Software remains the exclusive property of Local Measure and is
                                provided to the Client solely for the duration of their active subscription. Upon the expiration,
                                termination, or cancellation of the subscription, Client shall immediately delete and cease using
                                the Software.</li>
                            </ol>
                          </li>
                          <li>Reverse Engineering Prohibited:
                            <ol style={{ listStyleType: 'disc', paddingBottom: '0' }}>
                              <li style={{ marginLeft: '0em' }}>Client expressly agrees not to reverse engineer, decompile,
                              disassemble, or attempt to discover the source code of the Software, or engage in any similar
                                activity that would compromise the intellectual property rights of Local Measure.</li>
                            </ol>
                          </li>
                          <li>Limited Purpose:
                            <ol style={{ listStyleType: 'disc', paddingBottom: '0' }}>
                              <li style={{ marginLeft: '0em' }}>Client acknowledges that the Software and solutions provided
                              by Local Measure may only be used for the specific purposes contemplated by their subscription
                                agreement. Any use beyond the scope of the subscription is strictly prohibited.</li>
                            </ol>
                          </li>
                          <li>Non-Sharing and Non-Resale:
                            <ol style={{ listStyleType: 'disc', paddingBottom: '0' }}>
                              <li style={{ marginLeft: '0em' }}>Client shall not share, sublicense, resell, or otherwise make
                              the Software or any of its components available to third parties without the prior written
                                consent of Local Measure. Unauthorized sharing or resale may result in legal action.</li>
                            </ol>
                          </li>
                          <li>Compliance with EULA:
                            <ol style={{ listStyleType: 'disc', paddingBottom: '0' }}>
                              <li style={{ marginLeft: '0em' }}>Client acknowledges and agrees to abide by the terms of the
                              End User License Agreement (EULA) applicable to the Software, as &nbsp;
                                <a href='https://aws.amazon.com/marketplace/pp/prodview-jamlqlwdh7eeo' target="_blank" rel="noreferrer" >outlined in detail
                                here</a>
                              </li>
                            </ol>
                          </li>
                        </ol>
                        <div style={{ fontSize: '14px' }}>
                          Local Measure reserves the right to enforce these terms and conditions, and any violation may result in the suspension or termination of Client’s access to the Portal and Software.
                        </div>
                      </Box>
                </div>
              </DialogContent>
              <DialogActions>
                <Button sx={{ color: window.getConfig.MainColor }} onClick={handleCloseLicense}>OK</Button>
              </DialogActions>
          </Dialog>
        </div>
  )
}

Login.propTypes = {
  onSignIn: PropTypes.func
}
