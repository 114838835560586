import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import CssBaseline from '@mui/material/CssBaseline'

export default function Main () {
  // return <Navigate to="Stores"/>

  // useEffect(() => {
  //     const doGetParameter = () => {
  //         getParameter()
  //     }
  //     doGetParameter()
  // })

  return (
        <div style={{ margin: 'auto', maxWidth: '800px' }}>
            <CssBaseline/>
            <h1>Main Page</h1>
            <p>Local Measure Contact Center Command (LMCCC) allows users to esily configure some of the pre-built Local
              Measure solutions. Start by click on the navigation menu icon(
                <MenuIcon sx={{ verticalAlign: 'middle', color: window.getConfig.MainColor }}/>) in the top left corner
                to select a solution to configure.
            </p>
            <p>
              For security purposes Local Measure recommends that you log out once you have completed the required
              configuration. To do so, click on the user (<AccountCircle
              sx={{ verticalAlign: 'middle', color: window.getConfig.MainColor }} />) icon in the top right corner and
              select &apos;Logout&apos;.
            </p>
            <br/>
            <div style={{ fontSize: '12px' }}>Powered by<br/></div>
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/35333/Black.png" width="280px"></img>
        </div>
  )
}
