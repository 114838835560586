/* eslint-disable no-prototype-builtins */
import * as React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Logout from '@mui/icons-material/Logout'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Slide from '@mui/material/Slide'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Drawer from '@mui/material/Drawer'
import Tooltip from '@mui/material/Tooltip'
import { ListItemIcon } from '@mui/material'
import PropTypes from 'prop-types'

function HideOnScroll (props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  })

  return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
  )
}

HideOnScroll.propTypes = {
  window: PropTypes.object,
  children: PropTypes.object
}

function Home ({ isLoggedIn, isAdmin, fullName, onUserSignOut, props }) {
  const drawerWidth = 240
  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" color={window.getConfig.MainColor} sx={{ my: 2 }}>
          Navigation
        </Typography>
        <Divider />
        <List>
          <ListItem key="Home" disablePadding onClick={() => { navigate('/') }}>
              <Tooltip title="Navigates to the main  page" placement="right-end">
                <ListItemButton sx={{ textAlign: 'left' }}>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </Tooltip>
          </ListItem>

          {(window.getConfig.hasOwnProperty('DynamoDBBackOfficeTable') && window.getConfig.DynamoDBBackOfficeTable !== 'NONE') &&
            <ListItem key="BackOffice" disablePadding onClick={() => { navigate('BackOffice') }}>
              <Tooltip title="Navigates to the Backoffice DID configuration page" placement="right-end">
                <ListItemButton sx={{ textAlign: 'left' }}>
                  <ListItemText primary="Back Office" />
                </ListItemButton>
              </Tooltip>
          </ListItem>}
          <Divider/>
        </List>
      </Box>
  )

  const container = window !== undefined ? () => window.document.body : undefined
  const [anchorEl, setAnchorEl] = React.useState()
  const location = useLocation()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    onUserSignOut()
  }

  const getNavbarTitle = () => {
    if (location.pathname === '/') {
      return ''
    }
    let title = location.pathname.substring(1).replace('%20', ' ')
    console.log('title->', title)
    if (title.endsWith('/true')) {
      title = title.replace('/true', '')
    }
    if (title.endsWith('/false')) {
      title = title.replace('/false', '')
    }
    if (title.startsWith('User/')) {
      title = title.replace('User/', '')
    }
    if (title.includes('BusinessUnit/')) {
      const index = title.indexOf('/')
      title = title.substring(0, index)
    }

    if (!title.includes('@')) {
      // don't do this for email addresses - This will insert space between caps, BusinessUnit becomes Business Unit
      title = title.replace(/([A-Z])/g, ' $1').trim()
    }
    console.log('return tite->', title)
    return title
  }

  return (
        <React.Fragment>
        <CssBaseline />
        <HideOnScroll {...props}>
          <AppBar sx={{ bgcolor: window.getConfig.AppBarBackgroundColor }}>
            <Toolbar>
                {isLoggedIn && <IconButton
                    size="large"
                    edge="start"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, color: window.getConfig.MainColor }}
                    className='guidemenu'>
                      <Tooltip title="Opens the sidebar menu where additional resources can be found" placement="top">
                        <MenuIcon />
                      </Tooltip>
                </IconButton>}
                <Typography variant="h6" color={window.getConfig.MainColor} component="div" sx={{ flexGrow: 1 }}>
                {
                    getNavbarTitle()
                }
                </Typography>
                <Typography color={window.getConfig.MainColor}>{fullName}</Typography>
                {isLoggedIn && (
                <div>
                    <IconButton
                        className='guideprofile'
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        sx={{ color: window.getConfig.MainColor }}>
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}>
                        {/* <MenuItem onClick={()=>{window.alert("Clicked Profile");}}>
                          <ListItemIcon>
                            <AccountCircle sx={{color : constants.MainColor}} fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Profile</ListItemText>
                        </MenuItem> */}
                        <MenuItem onClick={handleLogout}>
                          <ListItemIcon>
                            <Logout sx={{ color: window.getConfig.MainColor }} fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Logout</ListItemText>
                        </MenuItem>
                    </Menu>
                </div>
                )}
            </Toolbar>
          </AppBar>

        </HideOnScroll>
        <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        <Toolbar />
          <Outlet/>
      </React.Fragment>

  )
}

Home.propTypes = {
  isLoggedIn: PropTypes.bool,
  isAdmin: PropTypes.bool,
  fullName: PropTypes.string,
  onUserSignOut: PropTypes.func,
  props: PropTypes.any
}

export default Home
