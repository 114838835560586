/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react'
import {
  createBrowserRouter,
  RouterProvider, Navigate
} from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { awsConfig } from './AWS/aws-export'
import { Amplify, Auth, Hub } from 'aws-amplify'

import Login from './Pages/Login'
import Home from './Pages/Home'
import Main from './Pages/Main'
import NoPage from './Pages/NoPage'
import BackOffice from './Pages/BackOffice'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
// import * as constants from "./Consts";
import { ThemeProvider, createTheme } from '@mui/material/styles'
// import { checkValidEngageAccount } from './APIUtils/BackendHandler'
import PropTypes from 'prop-types'

const fontTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'proxima-nova',
      textTransform: 'none'
    }
  }
})

export default function App () {
  // check if we have a valid token
  // let checkLogin = checkValidToken()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [fullName, setFullName] = useState('')
  const [upn, setUpn] = useState('')
  const [checkingCredentials, setCheckingCredentials] = useState(true) // used to disaplay a loading page while loading

  Amplify.configure(awsConfig)

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      // console.log(event)
      switch (event) {
        case 'signIn':
          // setUser(data);
          setIsLoggedIn(true)
          // console.log('user logged in HUB ->', data)
          break
        case 'signOut':
          // setUser(null);
          // console.log('user logged out')
          setIsLoggedIn(false)
          break
        case 'customOAuthState':
          // setCustomState(data);
      }
    })

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        if (currentUser) {
          setIsLoggedIn(true)
          setCheckingCredentials(false)
          checkAdminUser(currentUser)
          setUserName(currentUser)
        } else {
          setIsLoggedIn(false)
          setCheckingCredentials(false)
          setIsAdmin(false)
        }
      })
      .catch((error) => {
        console.log('Not signed in, error->', error)
        setIsLoggedIn(false)
        setCheckingCredentials(false)
      })

    return unsubscribe
  }, [])

  // useEffect(()=>{
  //   const checkAccount = async () => {
  //     const isValid = await checkValidEngageAccount()
  //     console.log('account valid?->', isValid)
  //   }
  //   checkAccount()
  // },[])

  function checkAdminUser (currentUser) {
    if (currentUser.signInUserSession.accessToken.payload.hasOwnProperty('cognito:groups')) {
      const groups = currentUser.signInUserSession.accessToken.payload['cognito:groups']
      // console.log('groups->',groups)
      setIsAdmin(groups.includes('Admin'))
    } else {
      setIsAdmin(false)
    }
  }

  function setUserName (currentUser) {
    // check for a UPN
    let uname = ''
    try {
      if (currentUser != null) {
        if (currentUser.hasOwnProperty('signInUserSession')) {
          if (currentUser.signInUserSession.hasOwnProperty('idToken')) {
            if (currentUser.signInUserSession.idToken.hasOwnProperty('payload')) {
              const x = currentUser.signInUserSession.idToken.payload
              uname = x?.identities[0].userId
              console.log('setting upn from userId')
              setUpn(uname)
            }
          }
        }
      }
    } catch (error) {
      uname = ''
      console.log('no userID found')
    }

    // console.log('checking username')
    if (currentUser != null) {
      // eslint-disable-next-line no-prototype-builtins
      if (currentUser.hasOwnProperty('pool')) {
        if (currentUser.pool.hasOwnProperty('storage')) {
          if (currentUser.pool.storage.hasOwnProperty('name')) {
            if (currentUser.pool.storage.emailAddress !== '') {
              setFullName(currentUser.pool.storage.emailAddress)
              if (uname.length === 0) {
                uname = currentUser.pool.storage.emailAddress
                console.log('setting upn from email in pool->', uname)
                setUpn(uname)
              }
              return
            }
          }
        }
        // storage did not have useable info
        if (currentUser.hasOwnProperty('signInUserSession')) {
          if (currentUser.signInUserSession.hasOwnProperty('idToken')) {
            if (currentUser.signInUserSession.idToken.hasOwnProperty('payload')) {
              if (currentUser.signInUserSession.idToken.payload.hasOwnProperty('email')) {
                const email = currentUser.signInUserSession.idToken.payload.email
                // console.log('token->')
                // console.log(currentUser.signInUserSession.idToken)
                setFullName(email)
                if (uname.length === 0) {
                  uname = email
                  console.log('setting upn from email in user session->', uname)
                  setUpn(uname)
                }
                return
              }
            }
          }
        }
      }
    }
    console.log('did not find a upn to use')
  }

  // async function updateUserGroups() {
  //   const currentUser = await Auth.currentAuthenticatedUser();
  //   const userSession = currentUser.getSignInUserSession();
  //   const refreshToken = userSession.getRefreshToken();
  //   currentUser.refreshSession(refreshToken, (err, session) => {
  //     currentUser.setSignInUserSession(session);
  //   });
  // }

  // function used to protect browser routes that should not be accessible without being logged in
  const Protected = ({ loggedIn, loading, children }) => {
    useEffect(() => {
      // console.log("rendered protected", { loggedIn });
    })
    if (loading) {
      return null
    }

    if (!loggedIn) {
      // User is not logged in so redirect them to the login page
      return <Navigate to="/"/>
    }

    // if we get here the user is logged in so we can go ahead and render the page
    return children
  }

  Protected.propTypes = {
    loggedIn: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.object
  }

  // function used to protect browser routes that should not be accessible without being logged in
  // const AdminProtected = ({ loggedIn, loading, isAdmin, children }) => {
  //   AdminProtected.propTypes = {
  //     loggedIn: PropTypes.bool,
  //     loading: PropTypes.bool,
  //     isAdmin: PropTypes.bool,
  //     children: PropTypes.array
  //   }

  //   useEffect(() => {
  //     // console.log("rendered admin protected", { isAdmin });
  //   })
  //   if (loading) {
  //     return null
  //   }

  //   if (!loggedIn) {
  //     // User is not logged in so redirect them to the login page
  //     return <Navigate to="/"/>
  //   }
  //   if (!isAdmin) {
  //     return <Navigate to="/"/>
  //   }

  //   // if we get here the user is an admin in so we can go ahead and render the page
  //   return children
  // }

  async function handleSignout () {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  function doLogin () {
    Auth.federatedSignIn()
  }

  function getPaths () {
    const childrenPaths = [
      {
        path: '/',
        element: <Main />
        // element: <Stores/>
      }
    ]
    // check if the backoffice config route should be added
    if (window.getConfig.hasOwnProperty('DynamoDBBackOfficeTable') &&
        window.getConfig.DynamoDBBackOfficeTable !== 'NONE' &&
        window.getConfig.DynamoDBBackOfficeTable !== '') {
      childrenPaths.push({
        path: 'BackOffice',
        element: <Protected loggedIn={isLoggedIn} loading={false}>
                    <BackOffice agentID={upn}/>
                  </Protected>
      })
    }

    const items = childrenPaths
    return items
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home isLoggedIn={isLoggedIn} isAdmin={isAdmin} fullName={fullName} onUserSignOut={handleSignout} />,
      errorElement: <NoPage />,
      children: getPaths()
    }
  ])

  if (isLoggedIn) {
    return <RouterProvider router={router} />
  } else if (checkingCredentials) {
    return (
      <div style={{ margin: '5px' }}>
        <Typography>Checking user login</Typography>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress sx={{ color: window.getConfig.MainColor }} />
        </Box>
    </div>
    )
  } else {
    return <Login onSignIn={doLogin}/>
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <ThemeProvider theme={fontTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
