const OAUTH_RESPONSE_TYPE = 'code'
const OAUTH_SCOPES = ['email', 'openid']

export const awsConfig = {
  Auth: {
    mandatorySignIn: true,
    region: window.getConfig.CognitoRegion,
    userPoolId: window.getConfig.CognitoUserPoolID,
    userPoolWebClientId: window.getConfig.CognitoAppClientID
  },
  oauth: {
    domain: window.getConfig.CognitoDomainPrefix + '.auth.' + window.getConfig.CognitoRegion + '.amazoncognito.com', // 'mt-test-lmccc.auth.eu-west-2.amazoncognito.com',
    scope: OAUTH_SCOPES,
    redirectSignIn: window.location.origin,
    responseType: OAUTH_RESPONSE_TYPE,
    clientId: window.getConfig.CognitoAppClientID,
    redirectSignOut: window.location.origin
  }
}
