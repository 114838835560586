/* eslint-disable no-prototype-builtins */
import { Auth } from 'aws-amplify'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { DynamoDBClient, ScanCommand, PutItemCommand } from '@aws-sdk/client-dynamodb'
// import { ConnectClient, ListPhoneNumbersCommand, ListQueuesCommand, ListUsersCommand, ListHoursOfOperationsCommand } from '@aws-sdk/client-connect'
// import { SSMClient, GetParametersByPathCommand } from '@aws-sdk/client-ssm'

// eslint-disable-next-line no-unused-vars
const getAccessToken = async () => {
  return Auth.currentSession()
    .then(res => {
      const accessToken = res.getAccessToken()
      return accessToken.getJwtToken()
    })
    .catch(error => {
      console.error('Error getting access token->', error)
      return null
    })
}

const getIdToken = async () => { // keep
  return Auth.currentSession()
    .then(res => {
      const idToken = res.getIdToken()
      return idToken.getJwtToken()
    })
    .catch(error => {
      console.error('Error getting id token->', error)
      return null
    })
}

async function getCredentials () { // keep
  // get id token and cognito config
  const idToken = await getIdToken()
  const loginData = {
    [window.getConfig.CognitoID]: idToken
  }

  return fromCognitoIdentityPool({
    clientConfig: { region: window.getConfig.CognitoRegion },
    identityPoolId: window.getConfig.CognitoPoolID,
    logins: loginData
  })
}

async function getDynamoDBClient () { // keep
  const credentials = await getCredentials()
  console.log('getting dynamodb client for region->', window.getConfig.ConnectRegion)
  const client = new DynamoDBClient({
    region: window.getConfig.ConnectRegion,
    credentials
  })
  return client
}

// #region BackOffice
export async function loadBackOfficeDIDs (agentID) { // keep
  console.log('must filter on->', agentID)
  try {
    const client = await getDynamoDBClient()
    const params = {
      TableName: window.getConfig.DynamoDBBackOfficeTable,
      ExpressionAttributeValues: {
        ':agentid': {
          S: agentID
        }
      },
      FilterExpression: 'Agent_id = :agentid'
    }
    const command = new ScanCommand(params)
    const response = await client.send(command)

    const entries = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      for (const key in items) {
        const entry = items[key]
        const entryInfo = {
          id: entry.id.S,
          Agent: entry.Agent_id.S,
          OfferVoicemail: entry.OfferVoicemail.BOOL,
          PhoneNumber: entry.PhoneNumber.S,
          RecordCall: entry.RecordCall.BOOL
        }
        entryInfo.VoicemailMessage = entry?.VoicemailMessage !== undefined ? entry.VoicemailMessage.S : ''
        entryInfo.UseCustomMessage = entry?.UseCustomMessage !== undefined ? entry.UseCustomMessage.BOOL : false
        entryInfo.VoicemailLanguage = entry?.VoicemailLanguage !== undefined ? entry.VoicemailLanguage.S : ''
        entries.push(entryInfo)
      }
    }
    return entries
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function saveBackofficeConfig (configEntry) { // keep
  try {
    const client = await getDynamoDBClient()

    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBBackOfficeTable,
      Item: {
        id: { S: configEntry.id },
        Agent_id: { S: configEntry.Agent },
        PhoneNumber: { S: configEntry.PhoneNumber },
        OfferVoicemail: { BOOL: configEntry.OfferVoicemail },
        VoicemailLanguage: { S: configEntry.VoicemailLanguage },
        VoicemailMessage: { S: configEntry.VoicemailMessage },
        UseCustomMessage: { BOOL: configEntry.UseCustomMessage },
        RecordCall: { BOOL: configEntry.RecordCall }
      },
      ReturnValues: 'NONE'
    }
    const command = new PutItemCommand(input)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

// #endregion

// #region backoffice extensions
export async function getBackOfficeExtensions (agentID) { // keep
  try {
    const client = await getDynamoDBClient()
    const params = {
      TableName: window.getConfig.DynamoDBBackOfficeExtensionsTable,
      ExpressionAttributeValues: {
        ':agentid': {
          S: agentID
        }
      },
      FilterExpression: 'Agent_id = :agentid'
    }
    const command = new ScanCommand(params)
    const response = await client.send(command)

    const entries = []
    const count = response.Count
    if (count > 0) {
      const items = response.Items
      for (const key in items) {
        const entry = items[key]
        const entryInfo = {
          id: entry.id.S,
          Agent: entry.Agent_id.S,
          OfferVoicemail: entry.OfferVoicemail.BOOL,
          Extension: entry.Extension.S,
          RecordCall: entry.RecordCall.BOOL
        }
        entryInfo.VoicemailMessage = entry?.VoicemailMessage !== undefined ? entry.VoicemailMessage.S : ''
        entryInfo.UseCustomMessage = entry?.UseCustomMessage !== undefined ? entry.UseCustomMessage.BOOL : false
        entryInfo.VoicemailLanguage = entry?.VoicemailLanguage !== undefined ? entry.VoicemailLanguage.S : ''
        entries.push(entryInfo)
      }
    }
    return entries
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function saveBackofficeExtensionConfig (configEntry) { // keep
  try {
    const client = await getDynamoDBClient()

    const input = { // PutItemInput
      TableName: window.getConfig.DynamoDBBackOfficeExtensionsTable,
      Item: {
        id: { S: configEntry.id },
        Agent_id: { S: configEntry.Agent },
        Extension: { S: configEntry.Extension },
        OfferVoicemail: { BOOL: configEntry.OfferVoicemail },
        VoicemailLanguage: { S: configEntry.VoicemailLanguage },
        VoicemailMessage: { S: configEntry.VoicemailMessage },
        UseCustomMessage: { BOOL: configEntry.UseCustomMessage },
        RecordCall: { BOOL: configEntry.RecordCall }
      },
      ReturnValues: 'NONE'
    }
    const command = new PutItemCommand(input)
    await client.send(command)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
// #endregion
